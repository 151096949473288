import { Group } from '@mantine/core';

import Spinner from '../Spinner';

const PageContentLoader = ({ ...restProps }) => {
  return (
    <Group justify="center" py="32px" {...restProps}>
      <Spinner />
    </Group>
  );
};

export default PageContentLoader;
