import { useEffect } from 'react';
import { Flex, useMantineTheme } from '@mantine/core';

import { jobApi, organisationApi, userApi } from '~/api';
import { extractResponseError } from '~/api/utils';
import { useAppContext } from '~/contexts/app';
import { useRequestStates } from '~/hooks';
import useAnalytics from '~/hooks/useAnalytics';
import { KELLO_STAGES } from '~/constants';

import { PageContentLoader } from '../molecules';
import { ParaXSmallStrong } from '../typography';
import { Button } from '../atoms';

function AppShell({ children }) {
  const analytics = useAnalytics();
  const theme = useMantineTheme();

  const {
    user,
    setUser,
    setOrganisation,
    setNumberOfOrgJobs,
    setOrgUsers,
    setCandidateArchiveReasons,
    setJobApplicationStages,
  } = useAppContext();
  const [fetchUserRequestStates, fetchUserRequestHandlers] = useRequestStates();
  const [fetchOrgRequestStates, fetchOrgRequestHandlers] = useRequestStates();
  const [fetchOrgJobsRequestStates, fetchOrgJobsRequestHandlers] = useRequestStates();
  // eslint-disable-next-line no-unused-vars
  const [fetchOrgUsersRequestStates, fetchOrgUsersRequestHandlers] = useRequestStates();
  // eslint-disable-next-line no-unused-vars
  const [fetchCandidateArchiveReasonsRequestStates, fetchCandidateArchiveReasonsRequestHandlers] = useRequestStates();
  // eslint-disable-next-line no-unused-vars
  const [fetchJobApplicationStagesRequestStates, fetchJobApplicationStagesRequestHandlers] = useRequestStates();

  const getUser = async () => {
    try {
      fetchUserRequestHandlers.pending();
      const resp = await userApi.fetchUser();
      const user = resp.result;
      setUser(user);
      fetchUserRequestHandlers.fulfilled(user);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchUserRequestHandlers.rejected(errorObj);
    }
  };

  const getOrganisation = async () => {
    try {
      fetchOrgRequestHandlers.pending();
      const resp = await organisationApi.fetch();
      const organisationInfo = resp.result;
      setOrganisation(organisationInfo);
      fetchOrgRequestHandlers.fulfilled(organisationInfo);
    } catch (error) {
      const errorObj = extractResponseError(error);
      fetchOrgRequestHandlers.rejected(errorObj);
    }
  };

  const getOrganisationJobs = async () => {
    try {
      fetchOrgJobsRequestHandlers.pending();
      const payload = {
        orgId: user.orgId,
        pageSize: 1,
      };
      const resp = await jobApi.fetchJobsForOrg(payload);
      setNumberOfOrgJobs(resp.result?.totalCount);
      fetchOrgJobsRequestHandlers.fulfilled(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchOrgJobsRequestHandlers.rejected(errorMessage);
    }
  };

  const getUsersInOrg = async () => {
    try {
      fetchOrgUsersRequestHandlers.pending();
      const resp = await organisationApi.fetchUsersForOrg();
      fetchOrgUsersRequestHandlers.fulfilled(resp.result);
      setOrgUsers(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchOrgUsersRequestHandlers.rejected(errorMessage);
    }
  };

  const getCandidateArchiveReasons = async () => {
    try {
      fetchCandidateArchiveReasonsRequestHandlers.pending();
      const resp = await organisationApi.fetchCandidateArchiveReasons();
      fetchCandidateArchiveReasonsRequestHandlers.fulfilled(resp.result);
      setCandidateArchiveReasons((prev) => (resp.result?.length ? resp.result : prev));
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchCandidateArchiveReasonsRequestHandlers.rejected(errorMessage);
    }
  };

  const getJobApplicationStages = async () => {
    try {
      fetchJobApplicationStagesRequestHandlers.pending();
      const payload = {
        kelloStages: KELLO_STAGES.SHORTLISTED,
      };
      const resp = await organisationApi.fetchJobApplicationStages(payload);
      fetchJobApplicationStagesRequestHandlers.fulfilled(resp.result);
      setJobApplicationStages(resp.result);
    } catch (error) {
      const { errorMessage } = extractResponseError(error);
      fetchJobApplicationStagesRequestHandlers.rejected(errorMessage);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    if (user) {
      getOrganisation();
      getUsersInOrg();
      getOrganisationJobs();
      getCandidateArchiveReasons();
      getJobApplicationStages();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      analytics.identify(user.id, {
        email: user.email,
        name: user.name,
        role: user.role,
        status: user.isBetaUser ? 'BETA' : 'NON_BETA',
      });
    }
  }, [user]);

  if (fetchUserRequestStates.fulfilled && fetchOrgRequestStates.fulfilled && fetchOrgJobsRequestStates.fulfilled) {
    return children;
  } else if (fetchUserRequestStates.pending || fetchOrgRequestStates.pending || fetchOrgJobsRequestStates.pending) {
    return <PageContentLoader h="100vh" />;
  } else if (fetchUserRequestStates.rejected || fetchOrgRequestStates.rejected || fetchOrgJobsRequestStates.rejected) {
    const { errorMessage, statusCode } = fetchUserRequestStates.error;

    if (![400, 401].includes(statusCode)) {
      // Any error other than 400 and 401 requires the error UI to be shown

      return (
        <Flex direction="column" align="center" justify="center" py="56px">
          <ParaXSmallStrong c={theme.app.colors.TEXT_NEGATIVE_NORMAL}>
            {errorMessage || 'Failed to load page content. Please try again.'}
          </ParaXSmallStrong>
          <Button mt="24px" onClick={getUser} size={Button.SIZES.SMALL} text="Try again" />
        </Flex>
      );
    } else {
      /* We can continue rendering children.
       * Login check would automatically happen at route level
       */
      return children;
    }
  }
}

export default AppShell;
