import Analytics from 'analytics';
// import googleAnalytics from '@analytics/google-analytics';
// import googleTagManager from '@analytics/google-tag-manager';

import * as utils from '~/utils';

import rudderAnalytics from './rudderAnalyticsPlugin';

export function initAnalytics() {
  const { hostname } = window.location;
  const isProductionWebsite = utils.isProductionHostname(hostname);
  const isStagingWebsite = utils.isStagingHostname(hostname);
  const isDevWebsite = utils.isDevHostname(hostname);

  // let gaMeasurementIds;
  // let gtmContainerId;
  let rudderAnalyticsWriteKey;
  let rudderAnalyticsDataPlaneUrl;
  let rudderAnalyticsCookieDomain;
  let debug = false;

  if (isProductionWebsite) {
    // gaMeasurementIds = ['G-1K4RRER2H0'];
    // gtmContainerId = 'GTM-MNLX44F';
    rudderAnalyticsWriteKey = '2m16L2UmdVxM8a1BAfzIA8Acdyd';
    rudderAnalyticsDataPlaneUrl = 'https://rudder.upraised.co';
    rudderAnalyticsCookieDomain = 'kello.ai';
  } else if (isStagingWebsite) {
    // gaMeasurementIds = ['G-38K3BK294Y'];
    // gtmContainerId = 'GTM-KCTZJSS';
    rudderAnalyticsWriteKey = '2mydZ4YiRAicjgH2GZOZVE8E4BQ';
    rudderAnalyticsDataPlaneUrl = 'https://rudder.staging.upraised.co';
    rudderAnalyticsCookieDomain = 'staging.kello.ai';
    debug = true;
  } else if (isDevWebsite) {
    rudderAnalyticsWriteKey = '2mydZ4YiRAicjgH2GZOZVE8E4BQ';
    rudderAnalyticsDataPlaneUrl = 'https://rudder.staging.upraised.co';
    rudderAnalyticsCookieDomain = 'dev.kello.ai';
    debug = true;
  } else {
    // gaMeasurementIds = ['G-K7TPD77TGN'];
    // gtmContainerId = 'GTM-TB2D27Q';
    rudderAnalyticsWriteKey = 'ABCD';
    rudderAnalyticsDataPlaneUrl = 'http://rudder.upraised.localhost:8080';
    debug = true;
  }

  // const gtmInstance = googleTagManager({
  //   containerId: gtmContainerId,
  // });
  // Patch `page` method to explicitly provide event name for page views
  // const origGtmInstancePageMethod = gtmInstance.page;
  // gtmInstance.page = (data) => {
  //   if (data?.payload?.properties && !data?.payload?.properties?.event) {
  //     data.payload.properties.event = 'pageview';
  //   }
  //   origGtmInstancePageMethod(data);
  // };

  const rudderAnalyticsPluginConfig = {
    writeKey: rudderAnalyticsWriteKey,
    dataPlaneUrl: rudderAnalyticsDataPlaneUrl,
  };
  if (rudderAnalyticsCookieDomain) {
    rudderAnalyticsPluginConfig.setCookieDomain = rudderAnalyticsCookieDomain;
  }

  const analytics = Analytics({
    app: 'kello-web',
    debug,
    plugins: [
      // googleAnalytics({
      //   measurementIds: gaMeasurementIds,
      // }),
      // gtmInstance,
      rudderAnalytics(rudderAnalyticsPluginConfig),
    ],
  });

  if (!isProductionWebsite) {
    window.KELLO_ENABLE_EVENT_LOGGER = () => {
      const removeListener = analytics.on('track', ({ payload }) => {
        const { event, properties } = payload;
        // eslint-disable-next-line no-console
        console.log('Track Event', event, properties);
      });
      return removeListener;
    };
  }

  return analytics;
}
