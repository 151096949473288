import { Flex, Spoiler, useMantineTheme } from '@mantine/core';
import { format } from 'date-fns';
import { isArray } from 'lodash-es';

import { DATE_FORMATS, NON_FILTER_INPUT_KEYS_FOR_SEARCH, SEARCH_FILTER_INPUT_TYPES } from '~/constants';
import { LabelXSmall, ParaSmall } from '~/components/typography';
import { Button } from '~/components/atoms';

import AlphabetsIcon from '~/assets/icons/alphabets.svg';
import FilterIcon from '~/assets/icons/filter-v2.svg';
import SaveIcon from '~/assets/icons/save.svg';
import SaveFilledIcon from '~/assets/icons/save-filled.svg';

import * as Styles from './SearchHistory.styled';

const Filter = ({ label, filters = [] }) => {
  let numberOfFiltersApplied = 0;
  let appliedFiltersNode;

  if (isArray(filters) && filters.length) {
    numberOfFiltersApplied = filters.length;
    appliedFiltersNode = (
      <>
        {filters.map((sb) => (
          <Styles.AppliedFilter key={sb}>
            <LabelXSmall maw="80px" truncate title={sb}>
              {sb}
            </LabelXSmall>
          </Styles.AppliedFilter>
        ))}
      </>
    );
  } else {
    numberOfFiltersApplied = 1;
    appliedFiltersNode = (
      <Styles.AppliedFilter>
        <LabelXSmall>{JSON.stringify(filters)}</LabelXSmall>
      </Styles.AppliedFilter>
    );
  }

  return (
    <Styles.FilterContainer>
      <Styles.AppliedFilterTypeContainer>
        <LabelXSmall fz="11px" fw="450" style={{ whiteSpace: 'nowrap', textTransform: 'capitalize' }}>
          {label}
        </LabelXSmall>
        <Styles.NumberOfAppliedFilters ml="8px">{numberOfFiltersApplied}</Styles.NumberOfAppliedFilters>
      </Styles.AppliedFilterTypeContainer>
      <Flex p="8px 12px">{appliedFiltersNode}</Flex>
    </Styles.FilterContainer>
  );
};

const SearchHistory = ({
  filters = [],
  searchHistory,
  onUseQuery,
  saveTalentPoolHistoryRequestStates,
  onToggleSave,
  ...rest
}) => {
  const theme = useMantineTheme();
  const { updatedAt, parameters, isSaved } = searchHistory;

  const toggleSaveSearchHistoryBtnClick = async () => {
    onToggleSave();
  };

  const filterMetadataByField = {};

  filters.forEach((filter) => {
    filterMetadataByField[filter.filterName] = filter;
  });

  const appliedFilterNodes = [];

  if (parameters) {
    Object.keys(parameters).forEach((param) => {
      const filterMetadata = filterMetadataByField[param];

      if (NON_FILTER_INPUT_KEYS_FOR_SEARCH.includes(param) || !filterMetadata) {
        return;
      }

      let parameterValues = parameters[param];

      const filterLabel = filterMetadata?.filterLabel || param;
      let appliedFilters = [];

      // Filters for date range
      if (filterMetadata?.inputType === SEARCH_FILTER_INPUT_TYPES.DATE_RANGE) {
        appliedFilters = parameterValues.values.map((val) => format(new Date(val), DATE_FORMATS.date));
      }
      // Filters for numerical range
      else if (filterMetadata?.inputType === SEARCH_FILTER_INPUT_TYPES.NUMERICAL_RANGE) {
        appliedFilters = parameterValues.values;
      }
      // Filters for other types
      else {
        // Filtering out default filters
        parameterValues = parameterValues.filter((val) => {
          const isDefaultFilter = filterMetadata.data.find((filter) => filter.value === val)?.isDefault;
          return !isDefaultFilter;
        });
        appliedFilters = parameterValues;
      }

      // Adding filter nodes if there are filters applied
      if (appliedFilters.length) {
        appliedFilterNodes.push(<Filter label={filterLabel} filters={appliedFilters} />);
      }
    });
  }

  return (
    <Styles.PromptCard {...rest}>
      <Flex justify="space-between" align="center" w="100%" h="34px">
        <Flex align="center">
          <LabelXSmall c={theme.app.colors.TEXT_NEUTRAL_WEAK}>
            {format(new Date(updatedAt), DATE_FORMATS.time)}
          </LabelXSmall>
        </Flex>

        <Styles.ActionsContainer size={Button.SIZES.X_SMALL} variant={Button.VARIANTS.OUTLINED}>
          <Button text="Use Now" size={Button.SIZES.X_SMALL} variant={Button.VARIANTS.OUTLINED} onClick={onUseQuery} />
          <Button
            icon={isSaved ? <SaveFilledIcon /> : <SaveIcon />}
            size={Button.SIZES.X_SMALL}
            variant={Button.VARIANTS.TRANSPARENT}
            color={Button.COLORS.NEUTRAL}
            onClick={toggleSaveSearchHistoryBtnClick}
            ml="16px"
            loading={saveTalentPoolHistoryRequestStates.pending}
            aria-label="Search history action button"
          />
        </Styles.ActionsContainer>
      </Flex>
      {parameters.search ? (
        <Flex mt="24px">
          <Styles.IconWrapper component={AlphabetsIcon} mt="2px" />
          {parameters.search ? (
            <Spoiler
              maxHeight={30}
              w="100%"
              showLabel="Show more"
              hideLabel="Hide"
              styles={{
                control: {
                  color: theme.app.colors.TEXT_NEUTRAL_STRONG,
                  textDecoration: 'underline',
                  fontSize: '14px',
                },
              }}
              ml="24px"
            >
              <ParaSmall c={theme.app.colors.TEXT_NEUTRAL_STRONG} title={parameters.search}>
                {parameters.search.split('\n').map((line) => (
                  <>
                    {line} <br />
                  </>
                ))}
              </ParaSmall>
            </Spoiler>
          ) : null}
        </Flex>
      ) : null}

      {appliedFilterNodes.length ? (
        <Flex mt="32px">
          <Styles.IconWrapper component={FilterIcon} mt="6px" />
          <Styles.FiltersContainer ml="24px">{appliedFilterNodes}</Styles.FiltersContainer>
        </Flex>
      ) : null}
    </Styles.PromptCard>
  );
};

export default SearchHistory;
