import { LoadingOverlay } from '@mantine/core';

import Spinner from '../Spinner';

/**
 * Renders a loader with white overlay.
 * The component `position` is `absolute` by default and it takes the parent container dimensions.
 * @param {ForwardRefExoticComponent<LoadingOverlayProps & RefAttributes<HTMLDivElement>>} props LoadingOverlay props
 */
const RouteFallbackLoader = ({ loaderProps, ...rest }) => {
  return (
    <LoadingOverlay
      visible
      transitionProps={{
        duration: 500,
      }}
      loaderProps={{
        children: <Spinner {...loaderProps} />,
      }}
      {...rest}
    />
  );
};

export default RouteFallbackLoader;
