import '@fontsource/open-sans/latin.css';
import '@fontsource/roboto-mono/latin.css';
import '@fontsource/roboto/latin.css';
import '@fontsource/inter/latin.css';
import '@fontsource/manrope/latin.css';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/charts/styles.css';
import { lazy, Suspense } from 'react';
import { createTheme, MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import * as Sentry from '@sentry/react';
// eslint-disable-next-line no-unused-vars
import { BrowserHistory } from 'history';
import { Route, Routes } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@emotion/react';

import AppRoot from '~/components/core/AppRoot';
import theme from '~/styles/theme/';
import { RouteFallbackLoader } from '~/components/molecules';
import AppContextProvider from '~/contexts/app';

import AppShell from './components/core/AppShell';
import { MODAL_IDS } from './constants';
import {
  CollectionActionModal,
  ConfigureJobStageModal,
  ConfirmationModal,
  InviteTeammatesModal,
  JobDetailsModal,
  MessageModal,
  PromptSearchHistoryModal,
  SearchFiltersModal,
  PinJobsModal,
  VideoModal,
} from './components/modals';
import { initAnalytics } from './lib/analytics';
import ProtectedRoute from './components/routes/ProtectedRoute';
import { GOOGLE_LOGIN_CLIENT_ID, GOOGLE_PERMISSIONS_CLIENT_ID } from './config';

const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const LandingPage = lazy(() => import('./pages/landingPage'));
const WelcomePage = lazy(() => import('./pages/welcome'));
const InvitePage = lazy(() => import('./pages/invite'));
const ImportCandidatesPage = lazy(() => import('./pages/importCandidates'));
const OAuthIntegrationPage = lazy(() => import('./pages/oAuthIntegration'));
const HomePage = lazy(() => import('./pages/home'));
const OrgActiveJobsPage = lazy(() => import('./pages/orgJobs'));
const JobDetailsPage = lazy(() => import('./pages/jobDetails'));
const TalentPoolPage = lazy(() => import('./pages/talentPool'));
const CollectionsPage = lazy(() => import('./pages/collections'));
const CollectionDetailsPage = lazy(() => import('./pages/collectionDetails'));
const TermsAndConditionsPage = lazy(() => import('./pages/terms'));
const PrivacyPolicyPage = lazy(() => import('./pages/privacyPolicy'));
const PlaygroundPage = lazy(() => import('./pages/playground'));
const ShareCandidatePage = lazy(() => import('./pages/shareCandidate'));

const analytics = initAnalytics();

const mantineTheme = createTheme({
  ...theme,
});

/**
 *
 * @param {{history: BrowserHistory}} props
 */
export default function App({ history }) {
  return (
    <Sentry.ErrorBoundary showDialog={false}>
      <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
        <MantineProvider theme={mantineTheme} withNormalizeCSS withGlobalStyles>
          <ThemeProvider theme={mantineTheme}>
            {/* To access theme object inside emotion-styled, TODO: Check for better solution */}
            <AnalyticsProvider instance={analytics}>
              <Notifications position="top-right" />
              <AppContextProvider>
                <ModalsProvider
                  modals={{
                    [MODAL_IDS.SEARCH_FILTERS_MODAL]: SearchFiltersModal,
                    [MODAL_IDS.SEARCH_HISTORY]: PromptSearchHistoryModal,
                    [MODAL_IDS.TALENT_POOL_SEARCH_HISTORY]: PromptSearchHistoryModal,
                    [MODAL_IDS.MESSAGE]: MessageModal,
                    [MODAL_IDS.INVITE_TEAM_MATES_MODAL]: InviteTeammatesModal,
                    [MODAL_IDS.COLLECTION_ACTION_MODAL]: CollectionActionModal,
                    [MODAL_IDS.CONFIRMATION_MODAL]: ConfirmationModal,
                    [MODAL_IDS.PIN_JOBS_MODAL]: PinJobsModal,
                    [MODAL_IDS.JOB_DETAILS_MODAL]: JobDetailsModal,
                    [MODAL_IDS.CONFIGURE_JOB_STAGES_MODAL]: ConfigureJobStageModal,
                    [MODAL_IDS.VIDEO_MODAL]: VideoModal,
                  }}
                >
                  <AppRoot history={history}>
                    <AppShell>
                      <Suspense fallback={<RouteFallbackLoader />}>
                        <Routes>
                          <Route path="/" element={<LandingPage />} />
                          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
                          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

                          <Route path="/welcome" element={<ProtectedRoute />}>
                            <Route index element={<WelcomePage />}></Route>
                          </Route>

                          <Route path="/invite" element={<ProtectedRoute />}>
                            <Route index element={<InvitePage />} />
                          </Route>

                          <Route path="/collections/:collectionId/invite" element={<ProtectedRoute />}>
                            <Route index element={<InvitePage />} />
                          </Route>

                          <Route path="/app" element={<ProtectedRoute />}>
                            <Route index element={<HomePage />} />
                            <Route path="jobs" element={<OrgActiveJobsPage />} />
                            <Route path="jobs/:jobSlug/:activeTab" element={<JobDetailsPage />} />
                            <Route
                              path="job/:jobSlug/candidate/:jobApplicationId/:action"
                              element={<ShareCandidatePage />}
                            />
                            <Route path="talent-pool" element={<TalentPoolPage />} />
                            <Route path="collections" element={<CollectionsPage />} />
                            <Route path="collections/:collectionId" element={<CollectionDetailsPage />} />
                            <Route
                              path="import"
                              element={
                                <GoogleOAuthProvider clientId={GOOGLE_PERMISSIONS_CLIENT_ID}>
                                  <ImportCandidatesPage />
                                </GoogleOAuthProvider>
                              }
                            />

                            <Route path="integration/:oAuthServiceName" element={<OAuthIntegrationPage />} />
                            <Route path="reconnect/:oAuthServiceName" element={<OAuthIntegrationPage />} />
                          </Route>

                          <Route path="/playground" element={<PlaygroundPage />} />
                          <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                      </Suspense>
                    </AppShell>
                  </AppRoot>
                </ModalsProvider>
              </AppContextProvider>
            </AnalyticsProvider>
          </ThemeProvider>
        </MantineProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  );
}
